<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>
  </system-template>
  <!-- <pre>{{code}}</pre> -->
  <div class="container-logo">

      <img :src="GrubHubLogo" alt="GrubHubLogo" />
  </div>
  </template>
<script>

import SystemTemplate from '@/templates/SystemTemplate.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import GrubHubLogo from '@/assets/GrubHubLogo.png';

export default {
  name: 'DashboardView',
  components: {
    SystemTemplate,
    BreadCrumb,
  },
  data() {
    return {
      GrubHubLogo,
      mode: 'edit',
      code: {
        header: [
          {
            key: 'Content-Type',
            value: 'application/json',
            readonly: true,
            required: true,
            active: true,
          },
          {
            key: 'Auth',
            value: 'Bearer ',
            readonly: false,
            required: true,
            active: true,
          },
        ],
        id: 2,
        protocol: 'GET',
        url: 'https://jsonplaceholder.typicode.com/todos/-1',
        body: '',
        active: true,
        createdAt: '2022-05-23T19:33:27.000Z',
        updatedAt: '2022-05-24T20:06:41.000Z',
        deletedAt: null,
        contentId: 3,
      },
      itemsBreadCrumb: [
        {
          text: 'Dashboard',
          disabled: true,
          href: '#',
        },
      ],
    };
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">

.container-logo{

    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    img{
      max-width:50%
    }
}
</style>
