<template>
  <v-card
    class="mx-auto"
  >
  <!-- <pre>{{item}}</pre> -->
    <v-list  background-color="white" v-model:opened="open" v-if="item?.subcategories?.length > 0">
      <!-- <v-list-item prepend-icon="mdi-home" title="Home"></v-list-item> -->

      <v-list-group
        background-color="white"
        v-for="subcategory in item.subcategories"
        v-show="subcategory.active === true"
        :key="subcategory.uuid"
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            background-color="white"
            prepend-icon="mdi-folder"
            :title="subcategory.name"
            :value="subcategory.name"></v-list-item>
        </template>

        <v-list-item
            v-for="article in subcategory.articles"
            v-show="article.active === true"
            :key="article.uuid"
            :value="article.title"
            :title="article.title"
            :to="getTo(subcategory, article)"
            prepend-icon="mdi-circle-small"
            background-color="white"
            @click="toggle"
            @keydown.enter="toggle"
          ></v-list-item>
        <!-- <v-list-item
            v-for="article in subcategories.articles"
            :key="article.uuid"
            :value="article.title"
            :title="article.title"
            prepend-icon="mdi-circle-small"
          ></v-list-item> -->

        <!-- <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Actions" value="Users"></v-list-item>
          </template>

          <v-list-item
            v-for="([title, icon], i) in cruds"
            :key="i"
            :value="title"
            :title="title"
            :prepend-icon="icon"
          ></v-list-item>
        </v-list-group> -->

      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: [
    'item',
  ],
  data: () => ({
    open: ['Users'],
    admins: [
      ['Management', 'mdi-account-multiple-outline'],
      ['Settings', 'mdi-cog-outline'],
    ],
    cruds: [
      ['Create', 'mdi-plus-outline'],
      ['Read', 'mdi-file-outline'],
      ['Update', 'mdi-update'],
      ['Delete', 'mdi-delete'],
    ],
  }),
  methods: {
    getSlug(string) {
      if (typeof (string) !== 'string') {
        return '';
      }
      const clean = string.trim().toLowerCase().replaceAll(/ +/ig, '-');
      return clean;
    },
    getTo(subcategory, article) {
      const { item, getSlug } = this;
      if (!item) {
        return null;
      }
      return `/docs/${getSlug(item.name)}/${getSlug(subcategory.name)}/${getSlug(article.title)}`;
    },
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>
