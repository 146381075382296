<template>
  <v-container fluid class="request-builder-container">
    <!-- {{mode}} -->
    <!-- <pre>{{requestObj}}</pre> -->
    <!-- <pre>{{props}}</pre> -->
    <!-- <pre>{{modelValue}}</pre> -->
    <!-- <pre>{{value}} </pre> -->
    <v-form>
      <v-row no-gutters>
        <v-col cols="12" sm="4" lg="4">
          <v-autocomplete
            v-model="requestObj.protocol"
            :items="methods"
            dense
            filled
            label="Method"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="7" lg="7">
          <v-text-field
            label="Url"
            v-model="requestObj.url"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" lg="1">
          <v-btn
            class="send-button elevation-1"
            style="    min-width: 70px;    width: 100%;    height: 55px;"
            variant="contained-flat"
            block
            :disabled="sending"
            @click.prevent="handleSendButton"
            :loading="sending">
              {{sending ? '...' : 'Send'}}
            </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" class="py-5">
            <v-tabs
              v-model="tab"
              background-color="white"
            >
              <v-tab value="headers">Headers</v-tab>
              <v-tab value="body">Body</v-tab>
            </v-tabs>

            <v-window v-model="tab">
              <v-window-item value="headers">
                <!-- // Headers Container -->
                <v-table>
                  <thead>
                    <tr>
                      <th v-if="mode !== 'view'" class="text-left readonlyheader">Read-only</th>
                      <th v-if="mode !== 'view'" class="text-left readonlyheader">Required</th>
                      <th class="text-left">Key</th>
                      <th class="text-left">Value</th>
                      <th class="text-left readonlyheader">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <pre>{{requestObj.header}}</pre> -->
                    <tr v-for="(header, index) in displayHeaders" :key="header.uuid">
                      <td  v-if="mode !== 'view'">
                        <v-checkbox
                          v-model="header.readonly"
                          :disabled="mode === 'view'"
                        ></v-checkbox>
                      </td>
                      <td  v-if="mode !== 'view'">
                        <v-checkbox
                          v-model="header.required"
                          :disabled="mode === 'view'"
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-text-field
                          label="Key"
                          v-model="header.key"
                          :disabled="mode === 'view' && (header.readonly || header.required)"
                          hide-details="auto"
                          class="my-3"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          label="Value"
                          v-model="header.value"
                          :disabled="mode === 'view' && header.readonly"
                          hide-details="auto"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-tooltip anchor="bottom" v-if="header.required !== true">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              class="ma-1"
                              color="primary"
                              @click="handleDeleteHeader(index)"
                              icon
                              v-bind="props"
                              size="x-small"
                            >
                              <v-icon size="x-small"> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Header</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <v-row>
                  <v-col>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      value="Add Header"
                      @click="addHeader"
                      >Add Header</v-btn>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item value="body" class="editor-container">
                <!-- // Body Container -->
                <!-- <v-container> -->
                  <!-- <v-row> @init="init" -->
                    <code-editor
                        v-model="requestObj.body"
                        lang="json"
                        theme="monokai"
                        style="height: 500px"

                        ref="ceditor"
                      />
                  <!-- </v-row> -->
                <!-- </v-container> -->
              </v-window-item>

            </v-window>
        </v-col>
      </v-row>

      <v-row v-if="showResponse">
        <v-col>
          <h3>Response</h3>
          <!-- <pre>{{response}}</pre> -->
        </v-col>
      </v-row>
      <v-row class="my-5" v-if="showResponse">
        <v-col>
          <div class="d-flex my-2">
          <div class="me-3">
            Status: <span data-status>{{response.status}}</span>
          </div>
          <div class="me-3">
            Time: <span data-time>{{response.time}}</span>ms
          </div>
          <div class="me-3">
            Size: <span data-size>{{response.size}}</span>
          </div>
      </div>

        </v-col>
      </v-row>
      <v-row v-if="showResponse">
        <v-col>
          <v-tabs
            v-model="tabResponse"
            background-color="white"
            class="my-5"
          >
            <v-tab value="headers">Headers</v-tab>
            <v-tab value="body">Body</v-tab>
          </v-tabs>

          <v-window v-model="tabResponse" class="my-5">
            <v-window-item value="headers">
              <!-- <div class="header-detail-container">

                <template v-for="header in response.headers" :key="header.uuid"
              >
                <div class="response-header-key">{{header.key}}</div>
                <div class="response-header-value">{{header.value}}</div>
              </template>
              </div> -->
              <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">Key</th>
                      <th class="text-left">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(header) in response.headers" :key="header.uuid">
                      <td>
                        <v-text-field
                          label="Key"
                          v-model="header.key"
                          disabled
                          hide-details="auto"
                          class="my-3"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          label="Value"
                          v-model="header.value"
                          disabled
                          hide-details="auto"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
            </v-window-item>
            <v-window-item value="body" class="editor-container">
              <code-editor
                v-model="response.body"
                lang="json"
                theme="monokai"
                style="height: 500px"
              />
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';
import prettyBytes from 'pretty-bytes';
import CodeEditor from 'vue3-code-editor';
// import { VAceEditor } from 'vue3-ace-editor';
// import 'ace-builds/src-noconflict/mode-text';
// import 'ace-builds/src-noconflict/theme-chrome';
// import { VAceEditor } from 'vue3-ace-editor';

export default {
  props: {
    mode: {
      // default: 'view',
      default: 'edit',
      type: String,
    },
    modelValue: {
      type: Object,
    },
    // value: {
    //   type: Object,
    // },

  },
  components: {
    CodeEditor,
    // VAceEditor,
  },
  data() {
    return {
      tab: 'header',
      tabResponse: 'body',
      methods: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      method: null,
      sample: {
        id: 1,
        protocol: 'get',
        url: 'http://example.com',
        header: [
          {
            key: 'Content-Type',
            value: 'application/json',
            readonly: true,
            required: true,
            active: true,
          },
          {
            key: 'Auth',
            value: 'Bearer ',
            readonly: false,
            required: true,
            active: true,
          },
        ],
        body: null,
        active: true,
        createdAt: '2022-05-17T12:25:35.000Z',
        updatedAt: '2022-05-17T12:25:35.000Z',
        deletedAt: null,
        contentId: 2,
        headerTemplate: {
          key: null,
          value: null,
          readonly: false,
          required: true,
          active: true,
        },
      },
      showResponse: false,
      response: {
        status: null,
        time: null,
        size: null,
        body: '',
      },
      requestObj: {
        id: null,
        protocol: null,
        url: null,
        header: [],
        body: '',
        active: true,
        contentId: null,
      },
      sending: false,
    };
  },
  computed: {
    displayHeaders() {
      return this.requestObj.header ?? [];
    },
  },
  methods: {
    init() {
      // eslint-disable-next-line global-require
      require('brace/theme/monokai');
      // eslint-disable-next-line global-require
      require('brace/mode/javascript');
      // eslint-disable-next-line global-require
      require('brace/mode/json');
    },
    updateResponseDetails(res) {
      this.response.status = res.status;
      this.response.time = res.totalTime;
      let { headers } = res;
      headers = Object.keys(res.headers).map((key) => ({ key, value: res.headers[key] }));
      this.response.headers = headers;
      this.response.body = JSON.stringify(res.data);
      this.response.size = prettyBytes(
        JSON.stringify(res.data).length
      + JSON.stringify(res.headers).length,
      );
    },
    async handleSendButton() {
      const response = await this.sendRequest();
      this.updateResponseDetails(response);
    },
    async sendRequest() {
      const startTime = new Date().getTime();
      let result = {};

      try {
        this.showResponse = false;
        this.sending = true;
        const { requestObj } = this;
        const headers = (requestObj?.header) ? requestObj.header.reduce((item, pair) => {
          if (!pair || !pair.key) {
            return item;
          }
          return { ...item, [pair.key]: pair.value };
        }, {}) : null;

        result = await axios({
          url: requestObj.url,
          method: requestObj.protocol,
          headers,
          data: requestObj.body ?? null,
        });
      } catch (error) {
        result = error.response;
      } finally {
        const endTime = new Date().getTime();
        result.totalTime = endTime - startTime;
        this.showResponse = true;
        this.sending = false;
      }
      return result;
    },
    addHeader() {
      const headerTemplate = { ...this.headerTemplate };
      const { header } = this.requestObj;
      header.push(headerTemplate);
      this.requestObj.header = [...header];
      this.broadcastChange();
      // this.requestObj.header.push(headerTemplate);
    },
    async handleDeleteHeader(header) {
      const { requestObj } = this;
      const cleanHeaders = [...requestObj.header];
      cleanHeaders.splice(header, 1);
      // const cleanHeaders = requestObj.header.filter((el) => el !== header);
      // debugger;
      // const cleanHeaders = requestObj.header.splice(index, 1);
      this.requestObj.header = [...cleanHeaders];
      this.broadcastChange();
      // setTimeout(() => {
      // this.broadcastChange();
      // }, 5);
    },
    cleanHeaders() {
      const { requestObj } = this;
      if (!Array.isArray(requestObj?.header)) {
        requestObj.header = [];
      }
      const cleanHeaders = requestObj.header.filter((el) => (
        (el?.key?.trim().length > 0
        || el?.value?.trim().length > 0)
      ));
      this.requestObj.header = [...cleanHeaders];
    },
    handleHeaderChange() {
      // const { header } = this.requestObj;
      // if (!Array.isArray(header)) {
      //   return;
      // }
      // this.cleanHeaders();
      // this.addHeader();
    },
    broadcastChange() {
      this.$emit('update:modelValue', { ...this.requestObj });
    },
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.showResponse = false;
      this.requestObj = newValue;
      // this.cleanHeaders();
      // this.addHeader();
    },
    // requestObj: {
    //   handler() {
    //     this.broadcastChange();
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.init();
    // this.$refs.ceditor.setTheme("ace/monokai");
    this.requestObj = { ...this.modelValue };
    // this.requestObj = { ...this.sample };
    // this.requestObj.active = false;
    this.cleanHeaders();
    this.addHeader();
  },
};
</script>

<style lang="scss" scoped>
.request-builder-container {
  .readonlyheader {
    width: 87px;
  }
  .editor-container {
    // background-color: rgb(47, 49, 41);
    background-color: #272822;
    height: 500px;
  }

  .send-button {
    height: 57px;
  }
  .header-detail-container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: .5rem 2rem;
  }
}
</style>
