<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>

    <div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="form.name"
                name="name"
                label="name"
                type="text"
                placeholder="name"
                required
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-center" cols="12" md="4">
              <v-btn
                class="mt-2"
                color="primary"
                value="save"
                @click="validate"
                :loading="loading"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Enabled</th>
                  <th class="text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in categories" :key="item.uuid">
                  <td>{{ item.name }}</td>
                  <td>
                    <v-checkbox
                      :model-value="item.active"
                      disabled
                    ></v-checkbox>
                  </td>
                  <td class="text-center">
                    <v-tooltip anchor="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          class="ma-1"
                          color="primary"
                          @click="goToEdit(item.id)"
                          icon
                          v-bind="props"
                          size="x-small"
                        >
                          <v-icon size="x-small"> mdi-pencil </v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip anchor="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          class="ma-1"
                          color="primary"
                          @click="goToSubcategories(item.id)"
                          icon
                          v-bind="props"
                          size="x-small"
                        >
                          <v-icon size="x-small"> mdi-eye </v-icon>
                        </v-btn>
                      </template>
                      <span>View Subcategories</span>
                    </v-tooltip>
                    <v-tooltip anchor="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          class="ma-1"
                          color="primary"
                          @click="gotToContent(item.id)"
                          icon
                          v-bind="props"
                          size="x-small"
                        >
                          <v-icon size="x-small"> mdi-code-json </v-icon>
                        </v-btn>
                      </template>
                      <span>View Content</span>
                    </v-tooltip>

                    <v-tooltip anchor="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          class="ma-1"
                          color="primary"
                          @click="dialog = true"
                          icon
                          v-bind="props"
                          size="x-small"
                        >
                          <v-icon size="x-small"> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Category</span>
                    </v-tooltip>

                    <v-dialog
                      v-model="dialog"
                      persistent
                      :retain-focus="false"
                    >
                      <v-card>
                        <v-card-title class="text-h5">
                          Confirm
                        </v-card-title>
                        <v-card-text>want to delete the record?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                          >
                            Disagree
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="handleDelete(item.id)"
                          >
                            Agree
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>
                </tr>
              </tbody>
            </v-table>

          </v-col>
        </v-row>
        <v-row v-if="categories.length > 0" justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                class="my-4"
                :length="total_pages"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </system-template>
</template>
<script>
import SystemTemplate from '@/templates/SystemTemplate.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import API from '@/lib/Api';

export default {
  name: 'CategoriesView',
  components: {
    SystemTemplate,
    BreadCrumb,
  },
  data: () => ({
    page: 1,
    page_size: 10,
    total_pages: 1,
    dialog: false,
    itemsBreadCrumb: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Categories',
        disabled: true,
        href: '#',
      },
    ],
    valid: true,
    loading: false,
    form: {
      name: '',
    },
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => v.length <= 250 || 'Name must be less than 250 characters',
    ],
    categories: [],
  }),
  watch: {
    page() {
      this.getCategories();
    },
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      this.valid = !valid;
      if (valid) {
        this.save();
      }
    },
    async save() {
      this.loading = true;
      const result = await API.post('/category', this.form);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.valid = true;
        this.$toast.error(result?.message ?? 'An error ocurred', {
          position: 'top',
        });
        return;
      }
      this.loading = false;
      this.$refs.form.reset();
      this.valid = true;
      this.$toast.success('Category created successfully', {
        position: 'top',
      });
      this.getCategories();
    },
    async getCategories() {
      try {
        const { data } = await API.get(`/categories?page=${this.page}&page_size=${this.page_size}`);
        this.categories = data.rows;

        const { page, page_size: pageSize, total_pages: totalPages } = data;
        this.page = page;
        this.page_size = pageSize;
        this.total_pages = totalPages;
      } catch (error) {
        console.error(error);
      }
    },
    goToEdit(id) {
      this.$router.push(`/category/${id}`);
    },
    goToSubcategories(id) {
      this.$router.push(`/category/${id}/subcategories`);
    },
    gotToContent(id) {
      this.$router.push(`/category/${id}/content`);
    },
    async handleDelete(id) {
      try {
        await API.delete(`/category/${id}`);
        this.$toast.success('Category deleted successfully', {
          position: 'top',
        });
        this.getCategories();
      } catch (error) {
        console.error(error);
        this.$toast.error(error, {
          position: 'top',
        });
      } finally {
        this.dialog = false;
      }
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
<style scoped>
</style>
