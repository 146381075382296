<template>
  <!-- <v-card> -->
  <v-layout>
    <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

    <v-app-bar color="#222222" prominent>

      <v-app-bar-nav-icon
       class="hidden-lg-and-up"
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title @click="goToHome" style="cursor: pointer;">
        <img :src="Logo" alt="" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="hidden-md-and-down">

      <slot name="topnav" ></slot>
      </div>

      <!-- <v-btn variant="text" icon="mdi-magnify"></v-btn>
        <v-btn variant="text" icon="mdi-filter"></v-btn>
        <v-btn variant="text" icon="mdi-dots-vertical"></v-btn> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" bottom temporary>
      <!-- <v-list
          :items="items"
        ></v-list> -->
      <slot name="sidenav"></slot>
    </v-navigation-drawer>

    <v-main elevation="0">
      <!-- <v-card-text>
          The navigation drawer will appear from the bottom on smaller size screens.
        </v-card-text> -->
      <slot name="default"></slot>
    </v-main>
  </v-layout>
  <!-- </v-card> -->
</template>

<script>
import Logo from '@/assets/logo.svg';

export default {
  data: () => ({
    Logo,
    drawer: false,
    group: null,
    items: [
      {
        title: 'Foo',
        value: 'foo',
      },
      {
        title: 'Bar',
        value: 'bar',
      },
      {
        title: 'Fizz',
        value: 'fizz',
      },
      {
        title: 'Buzz',
        value: 'buzz',
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    goToHome() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style lang="scss">
.v-btn--icon .v-icon {
  color: #ff8000;
}

.v-btn {
  color: #ff8000;
}

.v-toolbar-title__placeholder {
  color: #ff8000;
  display: grid;
  img {
    width: 100%;
    max-width: 190px;
  }
}

</style>
