// Styles
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line import/extensions
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import colors from 'vuetify/lib/util/colors';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({

  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.blueGrey.darken3, // #E53935
          secondary: colors.blueGrey.lighten4, // #FFCDD2
        },
      },
    },
  },

});
