<template>
  <v-row align="center">
    <v-col cols="12" class="mobile-menu pa-0" >
      <div class="activator-element pa-4"
        @click="toggle"
        @keydown.enter="toggle"

        >
        Menu
      </div>
      <div
        class="content-container "
        :class="{'fade-out-top': !open, 'fade-in-top': open}"
      >
        <article-browser v-if="item" :item="item" @toggle="toggle"></article-browser>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ArticleBrowser from '@/components/ArticleBrowser.vue';

export default {
  components: {
    ArticleBrowser,
  },
  data: () => ({
    open: false,
  }),
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
<style lang="scss">
.mobile-menu {
    .v-list-item--active {
      background-color: grey !important;
      color: white
    }
    .v-list-item--variant-text .v-list-item__overlay ,
  .v-list-item--variant-text .v-list-item__underlay{
    background-color: grey;
        color: white

  }
  .mobile-menu .v-list-item--active {
      background-color: grey;
      color: white
  }
}

</style>

<style lang="scss" scoped>
@import '../styles/animations.scss';

* {
  box-sizing: border-box;
}

.mobile-menu {
  position:relative;
  // background-color: $blue-grey-lighten-5;
  border: 1px solid;
  border-right: none;
  border-left: none;
  border-color: $blue-grey-lighten-5;

  // &:hover {
  //   // background-color: var(--v-theme-primary);
  //   background-color: $blue-grey-lighten-5;
  //   cursor: pointer;
  // }
}

.activator-element {
  color: $secondary;
  &:hover {
    // background-color: var(--v-theme-primary);
    background-color: $blue-grey-lighten-5;
    cursor: pointer;
  }
}
.content-container {
    // background-color: red !important;
    background-color: white !important;
    position: fixed;
    width: 100%;
    left: 0;
    height: -webkit-fill-available;
    z-index: 1;
    padding-top: 12px;
}

</style>
