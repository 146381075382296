<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>
    <div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="subCategory.name"
                name="name"
                label="name"
                type="text"
                placeholder="name"
                required
                :rules="nameRules"
              ></v-text-field>

              <v-checkbox
                v-model="subCategory.active"
                label="Enabled"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col class="d-flex justify-center" cols="12" md="12">
              <v-btn
              class="mt-2"
              color="primary"
              value="edit"
              @click="validate"
              :loading="loading"
              :disabled="!valid"
              >Edit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </system-template>
</template>
<script>
import SystemTemplate from '@/templates/SystemTemplate.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import API from '@/lib/Api';

export default {
  name: 'SubCategoryEdit',
  props: ['idCategory', 'idSubcategory'],
  components: {
    SystemTemplate,
    BreadCrumb,
  },
  data() {
    return {
      itemsBreadCrumb: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard',
        },
        {
          text: 'Categories',
          disabled: false,
          href: '/categories',
        },
        {
          text: 'Subcategories',
          disabled: false,
          href: '',
        },
        {
          text: 'Edit',
          disabled: true,
          href: '#',
        },
      ],
      valid: true,
      loading: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 250 || 'Name must be less than 250 characters',
      ],
      subCategory: {
        name: '',
      },
    };
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      this.valid = !valid;
      if (valid) {
        this.save();
      }
    },
    async save() {
      this.loading = true;
      const result = await API.patch(`/subcategory/${this.idSubcategory}`, this.subCategory);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.valid = true;
        this.$toast.error(
          result?.message ?? 'An error ocurred',
          {
            position: 'top',
          },
        );
        return;
      }
      this.loading = false;
      this.$refs.form.reset();
      this.valid = true;
      this.$router.push(`/category/${this.idCategory}/subcategories`);
      this.$toast.success(
        'Subcategory updated successfully',
        {
          position: 'top',
        },
      );
    },
    async getSubCategories() {
      try {
        const { data } = await API.get(`/subcategory/${this.idSubcategory}`);
        this.subCategory = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getSubCategories();

    const index = this.itemsBreadCrumb.findIndex((e) => e.text === 'Subcategories');
    this.itemsBreadCrumb[index].href = `/category/${this.idCategory}/subcategories`;
  },
};
</script>
<style scoped>
</style>
