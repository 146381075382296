<template>
    <div class="d-flex justify-space-between align-center">
      <v-btn
        @click="goBack"
        variant="text"
        icon="mdi-arrow-left"
        color="black"
      ></v-btn>

      <v-breadcrumbs :items="items">
        <template v-slot:text="{ item }">
          {{ item.text.toUpperCase() }}
        </template>
      </v-breadcrumbs>
    </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: ['items'],
  components: {
  },
  data: () => ({
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
</style>
