<template>
  <div class="NotFound">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <v-img
              :lazy-src="Logo"
              max-height="150"
              max-width="250"
              :src="Logo"
            ></v-img>
            <div class="display-3 mt-5">Page not found.</div>
            <div class="grey--text lighten-5">
              The page you are trying to get to never existed in this reality,
              or has migrated to a parallel universe.
            </div>
            <div class="paragraph-text mt-2">
              <br />
              <br />Try going back to home page and repeating your action. Or,
              contact helpdesk for support.
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Logo from '@/assets/logo.svg';

export default {
  name: 'AuthView',
  data() {
    return {
      Logo,
    };
  },
  methods: {
  },
};
</script>
<style scoped>
  .paragraph-text {
    font-size: 18px;
  }
</style>
