import { createApp } from 'vue';
import UUID from 'vue-uuid';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Toaster from '@meforma/vue-toaster';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
// import 'material-design-icons-iconfont/dist/material-design-icons.css';

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(UUID)
  .use(CKEditor)
  .use(Toaster)
  .mount('#app');
