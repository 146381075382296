import axios from 'axios';

axios.defaults.headers.common.token = process.env.VUE_APP_API_KEY;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

// axios.interceptors.request.use((request) => {
//   debugger;
//   // eslint-disable-next-line no-param-reassign
//   request.customData = request?.customData ?? {};
//   // eslint-disable-next-line no-param-reassign
//   request.customData.startTime = new Date().getTime();
//   return request;
// });

// function updateEndTime(response) {
//   debugger;
//   // eslint-disable-next-line no-param-reassign
//   response.customData = response?.customData ?? {};
//   // eslint-disable-next-line no-param-reassign
//   response.customData.time = new Date().getTime() - response.config.customData.startTime;
//   return response;
// }

// axios.interceptors.response.use(updateEndTime, (e) => Promise.reject(updateEndTime(e.response)));

export default {
  // CRUD methods----------------------------------------------------------------------
  async get(path) {
    try {
      const response = await axios
        .get(path);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async post(path, payload) {
    try {
      const response = await axios
        .post(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async put(path, payload) {
    try {
      const response = await axios
        .put(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async patch(path, payload) {
    try {
      const response = await axios
        .patch(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async delete(path) {
    try {
      const response = await axios
        .delete(path);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : true,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },
};
