<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>
    <div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="article.title"
                name="title"
                label="title"
                type="text"
                placeholder="title"
                required
                :rules="titleRules"
              ></v-text-field>

              <v-checkbox
                v-model="article.active"
                label="Enabled"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col class="d-flex justify-center" cols="12" md="12">
              <v-btn
              class="mt-2"
              color="primary"
              value="edit"
              @click="validate"
              :loading="loading"
              :disabled="!valid"
              >Edit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </system-template>
</template>
<script>
import SystemTemplate from '@/templates/SystemTemplate.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import API from '@/lib/Api';

export default {
  name: 'ArticleEdit',
  props: ['idCategory', 'idSubcategory', 'idArticle'],
  components: {
    SystemTemplate,
    BreadCrumb,
  },
  data: () => ({
    itemsBreadCrumb: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Articles',
        disabled: false,
        href: '/Articles',
      },
      {
        text: 'Edit',
        disabled: true,
        href: '#',
      },
    ],
    valid: true,
    loading: false,
    titleRules: [
      (v) => !!v || 'Title is required',
      (v) => v.length <= 250 || 'Title must be less than 250 characters',
    ],
    article: {},
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    async save() {
      this.loading = true;
      const result = await API.patch(`/articles/${this.idArticle}`, this.article);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.$toast.error(
          result?.message ?? 'An error ocurred',
          {
            position: 'top',
          },
        );
        return;
      }
      this.loading = false;
      this.$refs.form.reset();
      this.$router.push(`/category/${this.idCategory}/subcategory/${this.idSubcategory}/articles`);
      this.$toast.success(
        'Article updated successfully',
        {
          position: 'top',
        },
      );
    },
    async getArticle() {
      try {
        const { data } = await API.get(`/articles/${this.idArticle}`);
        this.article = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getArticle();
  },
};
</script>
<style scoped>
</style>
